import React, { useEffect, useState } from "react";
import $ from "jquery";

import {
  Checkbox,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  TextField,
  Chip,
  Grid,
  FormHelperText,
  FormGroup,
  RadioGroup,
  Radio,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { SelectChangeEvent } from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TbInfoCircle } from "react-icons/tb";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { ReactComponent as UploadIcon } from "../../../assets/svgs/upload.svg";
import { ReactComponent as LoadingIcon } from "../../../assets/svgs/animated/loading.svg";
import { useUploadDocumentMutation } from "../../../store/slices/FilingsSlice";

interface PropsType {
  ref?: any;
  children?: React.ReactNode;
  type: string;
  label: string;
  name: string;
  onChange?: React.ChangeEventHandler;
  error?: any;
  value?: any;
  hidden?: boolean;
  disabled?: boolean;
  conditionalClass?: string;
  placeholder?: string;
  title?: boolean;
  options?: any[];
  data?: any;
  portal?: string;
  setValue?: any;
  description?: any;
  fileName?: string;
  values?: any;
  fileType?: string;
  explainer?: string;
  explainers?: string[];
  fileIndex?: number,
  entryIndex?: number,
  handleUrlChange?: any,
  handleFileTypeChange?: any
  id?: string,
  entryCell?: string,
  isRequired?: boolean
}

const InputGroup = React.forwardRef(
  (
    {
      children,
      type,
      label,
      name,
      onChange,
      error,
      value = "",
      hidden,
      disabled,
      conditionalClass,
      options,
      placeholder,
      title,
      portal,
      setValue,
      description,
      fileName,
      values,
      fileType,
      explainer,
      explainers,
      fileIndex,
      entryIndex,
      handleUrlChange,
      handleFileTypeChange,
      id,
      isRequired,
      entryCell,
    }: PropsType,
    ref
  ) => {
    const [alternate, setAlternate] = useState("");
    const [uploadedFileName, setUploadedFileName] = useState("");
	const checkedIcon = <CheckBoxIcon fontSize="large" />;
	const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;

    useEffect(() => {
      if (fileName && setValue) {
        setValue(`${name}_fileName`, fileName);
      }
    }, [fileName]);

    useEffect(() => {
      if (fileType && setValue) {
        setValue(`${name}_fileType`, fileType);
      }
    }, [fileType]);

    useEffect(() => {
      if (type === "radio-ordered-with-text-default") {
        options?.filter((option: any) => {
          if (option.value === value) {
            setAlternate("");
          }
        });
      }
    }, [value]);

    useEffect(() => {
      if (type === "radio-ordered-with-text-default" && setValue) {
        if (alternate !== "") {
          $('input:radio[name="' + name + '"]:checked').prop("checked", false);
          // ($('input:radio[name=emptim]:checked').prop('checked', false) as any).checkboxradio("refresh");
        }

        setValue(alternate);
      }
    }, [alternate]);

    const [all, setAll] = useState(false);
    const handleMultiChange = (event: SelectChangeEvent) => {
      //console.log("On this click, all is " + event.target.value.includes("all"))
      if (event.target.value.includes("all")) {
        setValue(name, all ? [] : options?.map((option) => option.value));
        setAll(!all);
      } else {
        if (onChange) onChange(event as React.ChangeEvent<Element>);
      }
    };
    const handleChange = (event: SelectChangeEvent) => {
      if (onChange) onChange(event as React.ChangeEvent<Element>);
    };

    const [uploadDocument, { isLoading:fileLoading }] = useUploadDocumentMutation()

    const fileChangeHandler = async (e: any, idx:number) => {
      let url = "";
      let file = e.target.files[0];



      const formData = new FormData();
      formData.append("Attachment", file);
      formData.append("DocumentTitle", file.name);
      uploadDocument(formData).unwrap().then((resp) => {
        url = resp
        handleUrlChange(url,fileIndex, file.name, entryIndex)
        setUploadedFileName(file.name)
        handleFileTypeChange(file.type,fileIndex, entryIndex)
        setValue(name, url);
        setValue(`${name}_fileName`, file.name);
        setValue(`${name}_fileType`, file.type);

        setValue(name, url);
      });
    };

	const labelStyle = title ? 'title' : ''

	const isLabelWithIcon = label && explainers && type !== "checkbox-inline" && type !== "group-checkbox-radio" && (!placeholder || placeholder !== '')

    return (
		<div className={`input-group ${conditionalClass ?? ""} ${ error ? "error-within" : "" }`} >
			{/* Labels */}
			{
				label && !explainer && !explainers && type !== "checkbox-inline" && type !== "group-checkbox-radio" && (!placeholder || placeholder !== '') &&
				<label className={ `${type === "compliance-boolean" ? "compliance-boolean-label" : labelStyle} ` } htmlFor={name} >
					{label} 
                    
				</label>
			}
            {
                entryCell &&
                <small className="">Response will be filled in Cell Location: {entryCell}</small>
            }
			{
				explainer &&
				<div className="flex justify-between">
					<span>{label}</span>{" "}
					<span className="info">
						<TbInfoCircle></TbInfoCircle>
						<div className="tooltip">{explainer}</div>
					</span>
				</div>
			}
			{
				isLabelWithIcon &&
				<div className="flex justify-between">
					<span>{label}</span>{" "}
					<span className="info">
						<TbInfoCircle></TbInfoCircle>
						<div className="tooltip">
							{
								explainers.map((exp) => (
									<p key={exp}>{exp}</p>
								))
							}
						</div>
					</span>
				</div>
			}

			{/* Inputs */}
			{
				type === "number" &&  <input type="text" id={name} onChange={onChange} value={value} name={name} />
			}

			{
				type === "reminder-number" &&
				<input
					type="number"
					id={name}
					onChange={onChange}
					value={value}
					placeholder="Number of days before deadline"
					className="reminder-input"
					style={{
						width: "100%",
						paddingBlock: "15px",
						borderRadius: "5px",
						paddingInline: "10px",
						outline: "none",
					}}
				/>
			}

			{
				type === "checkbox" &&
				<div className={`${hidden ? "hide-checkbox-input" : " "} checkbox-input border border-borderGray rounded-[2px]`} >
					<Checkbox id={id} onChange={onChange} required={isRequired} icon={icon} checkedIcon={checkedIcon} className="" checked={value === 'true'} name={name} />
					<label htmlFor={id}>{children}</label>
				</div>
        	}

			{
				type === "checkbox-inline" &&
				<div className={`${hidden ? "hide-checkbox-input" : " "}`}>
					<FormControlLabel
						sx={{ "& .MuiFormControlLabel-label": { fontSize: "1.4rem" } }}
						className="title"
						control={
							<Checkbox id={name} onChange={onChange} checked={value} />
						}
						label={label}
					/>
					{
						explainer &&
						<FormHelperText style={{ fontSize: "1rem", marginTop: "-2rem" }}>
							{explainer}
						</FormHelperText>
					}
				</div>
			}

			{
				type === "group-checkbox-radio" &&
          		<FormGroup>
            		<RadioGroup onChange={onChange} name={name} value={value}>
              			{
							options?.map((item, index) => (
								<div key={item.label}>
									<FormControlLabel
										sx={{
											"& .MuiFormControlLabel-label": { fontSize: "1.4rem" },
										}}
										value={item.value}
										className="title"
										control={<Radio />}
										label={item.label}
									/>
									{
										item.explainer && (
										<FormHelperText style={{ fontSize: "1rem", marginTop: "-2rem" }} >
											{item.explainer}
										</FormHelperText>
									)}
								</div>
              				))
						}
            		</RadioGroup>
          		</FormGroup>
        	}

        	{
				type === "radio" &&
          		<div className="radio-group">
            		{
						options &&
						<Grid container spacing={2}>
							{
								options.map((option, index) => {
									return (
										<Grid item xs={6} key={option.value}>
											<>
												<input type="radio" id={name + index} onChange={onChange} name={name} value={option.value} />
												<label className={`${ value === option.value ? "radio-checked" : "" }`} htmlFor={name + index} >
													{option.name}
												</label>
											</>
										</Grid>
									);
								})
							}
              			</Grid>
            		}
          		</div>
        	}

        	{
				type === "file" &&
          		<div>
            		{ description && <div>{description}</div> }
            		<div className="image-input">
						<input
							accept='.png, .jpg, .pdf, .jpeg,'
							style={{ display: "none" }}
							onChange={(e) => fileChangeHandler(e, fileIndex ?? 0)}
							id={name}
							type="file"
                            required={isRequired}
							name={name}
						/>
						<label htmlFor={name}>
							<Button className={uploadedFileName ? "file-button-smaller" : ""} component="span" >
                                {uploadedFileName && <>{uploadedFileName || 'Choose File'}</>}
								{!fileLoading && <UploadIcon></UploadIcon>}
								{fileLoading &&  <LoadingIcon className="loading-icon"></LoadingIcon> }
							</Button>
              			</label>
            		</div>
          		</div>
			}

        	{
				type === "radio-ordered" &&
				<div className="radio-group ordered">
					<ol type="a">
						{
							options!?.map((option, index) =>
								<li key={option.value}>
									<input type="radio" id={name + index} onChange={onChange} name={name} value={option.value} />
									<label className={`${value === option.value ? "checked" : ""}`} htmlFor={name + index} >
										{option.name}
									</label>
								</li>
							)
						}
					</ol>
				</div>
        	}
			{
				type === "compliance-boolean" &&
				<div className="radio-group compliance-boolean">
					{
						options?.map((option, index) =>
							<label className={`${value === option.value ? "checked" : ""}`} key={option.value} htmlFor={name + index} >
								{option.name}{" "}
								<input
									type="radio"
									id={name + index}
									onChange={onChange}
									name={name}
									value={option.value}
								/>
							</label>
						)
					}
					{
						description &&
						<div className="description">
							<p> What's this? <span className="tooltip">{description}</span> </p>
						</div>
					}
				</div>
			}
        	{
				type === "radio-ordered-with-text-default" &&
          		<div className="radio-group ordered">
					<ol type="a">
						{
							options?.map((option, index) => (
								<li key={option.value}>
									<input type="radio" id={name + index} onChange={onChange} name={name} value={option.value} />
									<label className={`${value === option.value ? "checked" : ""}`} htmlFor={name + index} >
										{option.name}
									</label>
                  				</li>
                			))
						}
						<li>
							<input
								type="text"
								value={alternate}
								onChange={(e) => setAlternate(e.target.value)}
								placeholder={portal ?? "Others (Please specify)"}
							/>
						</li>
            		</ol>
          		</div>
        	}
			{
                type === "select" &&
                <Select
                    ref={ref}
                    id={name}
                    className="bg-white"
                    disabled={disabled}
                    onChange={handleChange}
                    value={value}
                    fullWidth
                    name={name}
                    displayEmpty
                    renderValue={(selected) => {
                        if (selected === '') {
                            return <span className="text-[#aeaeb4]">{placeholder ?? label}</span>;
                        }
                        return options?.find((option) => option.value === selected)?.name;
                    }}
                >
                    <MenuItem className="text-[#aeaeb4]" value="" disabled>
                        {placeholder ?? label}
                    </MenuItem>
                    {
                        options?.map((option, index) => (
                            <MenuItem key={option.name} value={option.value}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            }

			{
				type === "partial-select" && (
				<Select ref={ref} id={name} disabled={disabled} onChange={handleChange} value={value} fullWidth name={name} >
					{
						options?.map((option, index) => {
							return (
								<MenuItem key={option.value} value={option.value}  style={{ display: "flex", justifyContent: "space-between" }} >
									<span>{option.name}</span>
								</MenuItem>
							);
						})
					}
				</Select>
			)}
			{
				type === "multiselect" &&
				<Select multiple={true} ref={ref} id={name} labelId={name} disabled={disabled} onChange={handleChange} value={value} fullWidth name={name} >
					{
						options?.map((option, index) => {
							return (
								<MenuItem key={option.value} value={option.value}>
									{option.name}
								</MenuItem>
							);
						})
					}
				</Select>
			}
			{
				type === "multiselect-checkbox" &&
				<Select
					multiple={true} ref={ref} id={name} disabled={disabled} onChange={handleMultiChange} value={value} fullWidth name={name}
					renderValue={(selected) => `Selected: ${options?.length === selected.length ? 'All' : selected.length}`}
				>
            		<MenuItem value="all">
						<Checkbox
							checked={ options && options?.length > 0 && value?.length === options?.length }
							indeterminate={
								options && value.length > 0 && value.length < options.length
							}
							sx={{
								color: grey[800],
								"&.Mui-checked": {
									color: grey[900],
								},
								"& .MuiSvgIcon-root": { fontSize: 20 },
							}}
              			/>
						Select All
					</MenuItem>
            		{
						options?.map((option, index) => {
							return (
								<MenuItem key={option.value} value={option.value}>
									<Checkbox
										checked={value.indexOf(option.value) > -1}
										sx={{
											color: grey[800],
											"&.Mui-checked": {
												color: grey[900],
											},
											"& .MuiSvgIcon-root": { fontSize: 20 },
										}}
									/>
									{option.name}
								</MenuItem>
							);
						})
					}
				</Select>
        	}

			{
				type === "date" &&
          		<input type="date" id={name} required={isRequired} onChange={onChange} value={value} name={name}/>
        	}

			{
				type === "text" &&
				<input type="text" id={name} required={isRequired} onChange={onChange} value={value} placeholder={placeholder ?? label} name={name} />
			}

			{
				type === "password" &&  <input type="password" id={name} onChange={onChange} value={value} />
			}

			{
				type === "textbox" &&  <textarea placeholder={placeholder ?? label} id={name} onChange={onChange} value={value} rows={6} name={name} />
			}

        	{
          		type === "autocomplete" &&
				<Autocomplete
					multiple
					className="autocomplete"
					autoSelect
					options={[]}
					value={value}
					onChange={(e, newval: any, reason) => {
						setValue(name, newval);
					}}
					freeSolo
					renderTags={(value: readonly string[], getTagProps) =>
						value.map((option: string, index: number) =>
							<Chip
								variant="outlined"
								label={option}
								{...getTagProps({ index })}
							/>
						)
					}
					renderInput={(params) => <TextField {...params} />}
				/>
        	}

			{/* Error display */}
			{
				type !== "checkbox" &&
				<div className={`error ${error ? "visible" : ""}`}>
					{error?.message ?? ""}
				</div>
			}

			{
				type !== "checkbox" && <span className="children">{children}</span>
			}
      	</div>
	)}
);

export default InputGroup;
