import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios, { baseUrl } from '../../utils/AxiosConfig';
import unAuthenticatedAxios from 'axios'
import { LookUpOptionType, ResultType, licenseClassType } from '../../utils/typings/lookup';



export const lookuprefactorApi = createApi({
    reducerPath: 'lookuprefactorApi', 
    tagTypes: ['AllLookUp', 'LicenseClass', 'CompanyTypes', 'Countries', 'CountryCheckList', 'CountryCheckListItem', 'MultipleLicenseClasses'], 
    keepUnusedDataFor: 30 * (60 * 1000), 
    refetchOnReconnect: true, 
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        getAllLookUp: builder.query<ResultType, void>({
            queryFn: () => {
                return unAuthenticatedAxios.get(`${baseUrl}/api/Lookup/all`)
                    .then((response) => {
                        let tempData:ResultType = {
                            companyTypes: [],
                            industryTypes: [],
                            complianceCategories: [],
                            complianceFrequencies: [],
                            regimeCategories: [],
                            reminderFrequencies: []
                        };
                        if (response.data.result) {
                            tempData['companyTypes'] = response.data.result.companyTypes.map((item:LookUpOptionType) => {
                                item.value = item.id
                                return item
                            })
                            tempData['industryTypes'] = response.data.result.industryTypes.map((item:LookUpOptionType) => {
                                item.value = item.id
                                return item
                            })
                            tempData['complianceCategories'] = response.data.result.complianceCategories.map((item:LookUpOptionType) => {
                                item.value = item.id
                                return item
                            })
                            tempData['complianceFrequencies'] = response.data.result.complianceFrequencies.map((item:LookUpOptionType) => {
                                item.value = item.id
                                return item
                            })
                            tempData['reminderFrequencies'] = response.data.result.reminderFrequencies.map((item:LookUpOptionType) => {
                                item.value = item.id
                                return item
                            })
                        }

                        return {
                            data: tempData
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'AllLookUp' }]
        }),

        getLicenseClass: builder.query<string[], licenseClassType>({ //<ResultType, QueryArgument>
            queryFn: ({ countryCode, industryType }) => {
                return axios.get(`api/Lookup/license-class/${countryCode}/${industryType}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'LicenseClass' }]
        }),
        getMultipleLicenseClass: builder.query<string[], { countryCode: string, industryTypes: string[]}>({ //<ResultType, QueryArgument>
            queryFn: ({ countryCode, industryTypes }) => {
                const url = new URL(`${baseUrl}api/Lookup/${countryCode}/license-classes`)
                for(let id of industryTypes){
                    url.searchParams.append('industryTypeIds', id)
                }
                const parseUrl = url.toString()
                const urlIndex = parseUrl.indexOf(`.com`) + 4
                const urlResult = parseUrl.substring(urlIndex)
                return axios.get(`${urlResult}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'MultipleLicenseClasses' }]
        }),
        //THESE ENDPOINTS ARE NOT BEING USED ANYWHERE IN THE CODEBASE
        getCompanyTypes: builder.query<string[], void>({ //<ResultType, QueryArgument>
            queryFn: () => {
                return axios.get(`api/Lookup/company-types`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch((err) => {
                        return {
                            error: {
                                status: err?.response?.status,
                                data: err?.response?.data,
                                statusText: err?.code
                            }
                        }
                    });
            },
            providesTags: () => [{ type: 'CompanyTypes' }]
        }),

        getCountries: builder.query<string[], void>({ //<ResultType, QueryArgument>
            queryFn: () => {
                return axios.get(`api/Lookup/countries`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch((err) => {
                        return {
                            error: {
                                status: err?.response?.status,
                                data: err?.response?.data,
                                statusText: err?.code
                            }
                        }
                    });
            },
            providesTags: () => [{ type: 'Countries' }]
        }),

    getCountryCheckList: builder.query<string[], void>({ //<ResultType, QueryArgument>
            queryFn: (countryCode) => {
                return axios.get('api/Lookup/country-checklists/' + countryCode)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch((err) => {
                        return {
                            error: {
                                status: err?.response?.status,
                                data: err?.response?.data,
                                statusText: err?.code
                            }
                        }
                    });
            },
            providesTags: () => [{ type: 'CountryCheckList' }]
        }),
        getCountryCheckListItem: builder.query<string[], any>({ //<ResultType, QueryArgument>
            queryFn: (data) => {
                return axios.get('api/Lookup/country-checklists/' + data.countryCode +'/' + data.checklistId)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch((err) => {
                        return {
                            error: {
                                status: err?.response?.status,
                                data: err?.response?.data,
                                statusText: err?.code
                            }
                        }
                    });
            },
            providesTags: () => [{ type: 'CountryCheckListItem' }]
        }),
    })
})


export const { 
    useGetAllLookUpQuery, useGetLicenseClassQuery, useGetMultipleLicenseClassQuery, useGetCompanyTypesQuery, 
    useGetCountriesQuery, useGetCountryCheckListQuery, useGetCountryCheckListItemQuery 
} = lookuprefactorApi

