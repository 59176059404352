

import BannerImage from '../../assets/svgs/mor-assets/business-banner.svg';

const BusinessSection = () => {
    return (
        <section className='md:px-40  mb-[-60px] flex items-center justify-center mt-20 py-10'>
            <div
                className="w-full card-width bg-[#A7D1A766] rounded-[10px] px-10  md:px-16 py-10 md:py-24 bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: `url(${BannerImage})` }}
            >
                <div className="md:max-w-[60%] max-w-[100%] flex flex-col items-start justify-start mb-16">
                    <p className="md:text-[52px] text-[38px] md:text-start text-start text-[#104330] tracking-tight leading-tight font-[600]">
                        Ready, set, run your business with ease today
                    </p>
                </div>
                <div className='flex items-start justify-start  md:my-0 w-full'>
                    <a href='/login' className="bg-[#104330] font-[600] text-white rounded-[2px] px-10 py-3">
                        Get Started
                    </a>
                </div>
            </div>
        </section>
    );
}

export default BusinessSection;
