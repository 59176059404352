import ModalWrapper from "../ModalWrapper";

type PropsType = {
    onClose: () => void
    open: boolean
}
const VideoPlayerModal = ({ onClose, open}: PropsType) => {
    if(!open) return null
    return ( 
        <ModalWrapper onClose={onClose} wide={true} height="70vh">
            <div className="iframe-container" style={{ position:'relative'}}>
                <iframe className='absolute top-0 left-0 w-full h-full'
                    src="https://nbukssa.blob.core.windows.net/autocomplydemovideo/Auto_comply_demo.mp4" 
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    title="Auto Comply Demo Video"
                    allowFullScreen
                >
                </iframe>
            </div>
        </ModalWrapper>
     );
}
 
export default VideoPlayerModal;