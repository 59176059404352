import React from 'react';

const Loader = ({ type, loaderType }: { type?: string; loaderType?: 'spinner' | 'dots' }) => {
    return (
        <div className={`${type === 'button' ? 'h-8' : 'h-[200px]'} w-full flex items-center justify-center text-gray`}>
            {loaderType === 'dots' ? (
                <div className="flex space-x-2">
                    <div className="w-4 h-4 bg-white rounded-full animate-dot-bounce"></div>
                    <div className="w-4 h-4 bg-white rounded-full animate-dot-bounce animation-delay-200"></div>
                    <div className="w-4 h-4 bg-white rounded-full animate-dot-bounce animation-delay-400"></div>
                </div>
            ) : (
                <div className={`${type === 'button' ? 'w-8 h-8 border-2 border-lightGray border-t-2 border-t-[#228B22]' : 'w-[40px] h-[40px] border-[4px] border-lightGray border-t-[4px] border-t-[#228B22]'} rounded-full animate-spin`}></div>
            )}
        </div>
    );
};

export default Loader;
