import React, { useState } from 'react';
import { IoIosArrowDropleft, IoIosArrowDropright } from 'react-icons/io';

const AutoComplyGrayLogo = React.lazy(() => import('../../assets/svgs/mor-assets/autocomply-gray-logo.svg').then(module => ({ default: module.ReactComponent })));

interface CompassDataItem {
    id: string;
    name: string;
    frequency: string;
    regulator: string;
    description: string;
}

const CompassResult = ({ compassData }: { compassData: CompassDataItem[] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showSignUp, setShowSignUp] = useState(false);

    const handleNext = () => {
        if (currentIndex === compassData.length - 1) {
            setShowSignUp(true);
        } else {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (showSignUp) {
            setShowSignUp(false);
        } else if (currentIndex > 0) {
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? compassData.length - 1 : prevIndex - 1
            );
        }
    };

    return (
        <div className={"w-full bg-white flex flex-col items-start p-10  min-h-[260px] justify-between rounded-[4px]"}>
            {!showSignUp ? (
                <>
                    {compassData?.length > 0 && (
                        <div className="py-4 w-full rounded-[4px] gap-16 flex-col items-start justify-start">

                            <div className="flex w-full items-start flex-col md:flex-row justify-between">

                                <div className="md:max-w-[70%] max-w-[100%]">
                                    <h2 className="text-black text-[18px] md:text-[22px] font-[700] tracking-[-1px] mb-5 ">
                                        {currentIndex + 1}. {compassData[currentIndex].name} 
                                    </h2>
                                </div>

                                <p className="text-[#ef4c49] text-[14px] md:text-[18px] rounded-[45px] py-2 px-6 bg-[#EF4C491A] mb-5">
                                    Filed {compassData[currentIndex].frequency}
                                </p>

                            </div>

                            <p className="text-[14px] md:text-[20px] mt-[8px]">
                                 {compassData[currentIndex].description}
                            </p>

                            {
                                compassData[currentIndex].regulator ?
                                <p className="text-[20px] mt-10">
                                    <span className="font-[600]">Regulator:</span> {compassData[currentIndex].regulator}
                                </p>
                                : null
                            }
                        </div>
                    )}

                    <div className="flex gap-2 items-center justify-between w-full  mt-5">
                        
                        <div className='flex gap-2 items-center'>
                            <button
                                onClick={handlePrev}
                                disabled={currentIndex === 0}
                                className={currentIndex === 0 ? 'cursor-not-allowed' : ''}
                            >
                                <IoIosArrowDropleft
                                    className={currentIndex === 0 ? 'text-[#c3c4cd]' : 'text-primaryGreen'}
                                    size={40}
                                />
                            </button>

                            <button onClick={handleNext}>
                                <IoIosArrowDropright className="text-primaryGreen" size={40} />
                            </button>
                        </div>

                        <AutoComplyGrayLogo />
                    </div>
                </>
            ) : (
                <div className="sign-up-container w-full h-full flex flex-col items-start justify-between gap-6">

                    <p className="text-black text-[24px] font-[700] tracking-[-1px] mb-5">Sign up on AutoComply</p>

                    <p className="text-[20px]">
                        Compliance doesn't have to be complicated. We simplify the process by providing you with a list of obligations required of your company, keeping you compliant and ensuring you never miss a deadline.
                    </p>

                    <p className="text-[20px]">Start your one month trial today</p>

                    <div className="w-full flex items-start md:items-center flex-col md:flex-row justify-between mt-10">

                        <div className="flex items-start md:items-center gap-4 mb-5 md:mb-0">
                            <button onClick={handlePrev}>
                                <IoIosArrowDropleft className="text-primaryGreen" size={40} />
                            </button>

                            <a href='/login' className="py-4 px-10 font-[600] bg-primaryGreen text-white rounded-[4px]">
                                Start your free trial
                            </a>
                        </div>
                        <AutoComplyGrayLogo />
                    </div>
                </div>
            )}
            
        </div>
    );
};

export default CompassResult;
