

import MultiNationalImage from './../../assets/svgs/mor-assets/multinational.webp';
import StartupImage from './../../assets/svgs/mor-assets/startups.webp';
import WomanImage from './../../assets/svgs/mor-assets/womanfinance.webp';
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";
import { MdOutlineArrowOutward } from "react-icons/md";

const BuiltForSection = () => {
    const { isVisible, elementRef } = useAnimateOnScroll(0.1);
    
    return ( 
        <div ref={elementRef} className={`w-full flex items-center justify-center md:px-40   landing-page-wrapper ${
            isVisible
                ? 'animate-slideInFromBottom opacity-100 translate-y-0'
                : 'opacity-0 translate-y-10'
        }`}>
            <div className=' card-width'>

                <div className="my-[7.5rem] ">

                    <div className="w-full flex-col flex items-center gap-y-[1.1rem]  mb-[4rem] justify-center ">
                        <h2 className="md:text-[52px] text-[32px] md:text-center tracking-[-1px] text-start text-black font-[600]">Built for the Best Businesses</h2>
                        <p className="text-[18px] md:text-[24px] md:max-w-[50%] text-left md:text-center mt-[18px]">A compliance suite that ensures good standing with regulatory bodies</p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

                    <div className="group relative rounded-[12px]  overflow-hidden shadow-lg">
                            <img
                                src={WomanImage}
                                alt="woman reading a book"
                                className="h-[600px] w-full object-cover transition-transform duration-500 group-hover:scale-105"
                            />

                            <div className="absolute bottom-0 w-full bg-[#104330] text-white transition-all duration-500 pb-10 px-8 h-[165px] group-hover:h-[100%] flex flex-col justify-end">
                                <h1 className="text-[24px] font-[600] mb-2">Financial Companies</h1>
                                <div className="overflow-hidden text-[18px]  text-ellipsis transition-all duration-500 max-h-[76px] group-hover:max-h-[220px]">
                                    <p className="group-hover:block hidden" >
                                        From daily returns to quarterly audits, AutoComply simplifies the obligation stack for compliance teams in the
                                        financial industry.<br /> <br /> We help you track returns to your regulator, collaborate with team members, and keep to deadlines.
                                    </p>
                                    <p className="group-hover:hidden block">
                                    From daily returns to quarterly audits, AutoComply simplifies the obligation stack for compliance teams in the
                                    financial industry...
                                        
                                    </p>
                                    <div className="mt-4">
                                        <a href='/login' className=" text-[#FFF2E8] flex items-center leading-5  underline">
                                            LEARN MORE <MdOutlineArrowOutward className="text-[#FFF2E8]" size={20} />
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="group relative rounded-[12px] overflow-hidden shadow-lg">
                            <img
                                src={MultiNationalImage}
                                alt="Office building"
                                className="h-[600px] w-full object-cover transition-transform duration-500 group-hover:scale-105"
                            />
                            <div className="absolute bottom-0 w-full bg-[#104330] text-white transition-all duration-500 pb-10 px-8 h-[165px] group-hover:h-[100%] flex flex-col justify-end">

                            <h1 className="text-[24px] font-[600] mb-2">Multinationals</h1>
                            
                            <div className="overflow-hidden text-[18px] text-ellipsis transition-all duration-500 max-h-[76px] group-hover:max-h-[220px]">
                                
                                    <p className="group-hover:block hidden" >
                                        For organizations operating in multiple countries, AutoComply keeps you abreast of your taxes, legal obligations,
                                        deadlines, and potential risk exposure in those markets.
                                        
                                    </p>
                                
                                    <p className="group-hover:hidden block">
                                        For organizations operating in multiple countries, AutoComply keeps you abreast of your taxes, legal obligations...
                                        
                                    </p>
                              
                                <div className="mt-8">
                                    <a href='/login' className=" text-[#FFF2E8] flex items-center leading-5  underline">
                                        LEARN MORE <MdOutlineArrowOutward className="text-[#FFF2E8]" size={20} />
                                    </a>
                                </div>
                            </div>
                            
                            
                            </div>
                        </div>

                        

                        <div className="group relative rounded-[12px] overflow-hidden shadow-lg">
                            <img
                                src={StartupImage}
                                alt="office people"
                                className="h-[600px] w-full object-cover transition-transform duration-500 group-hover:scale-105"
                            />
                            <div className="absolute bottom-0 w-full bg-[#104330] text-white transition-all duration-500 pb-10 px-8 h-[165px] group-hover:h-[100%] flex flex-col justify-end">
                                <h1 className="text-[24px] font-[600] mb-2">Startups</h1>
                                <div className="overflow-hidden text-[18px] text-ellipsis transition-all duration-500 max-h-[76px] group-hover:max-h-[220px]">
                                    <p className="group-hover:block hidden">
                                         Expanding into new markets has never been easier, since Autocomply.  Our obligation discovery dashboard lets you figure out hiring requirements, new taxes, licenses and regulatory obligations as you grow across states or expand globally.
                                    </p>
                                    <p className="group-hover:hidden block">
                                        Expanding into new markets has never been easier, since Autocomply...
                                    </p>
                                    <div className="mt-8">
                                        <a href='/login' className=" text-[#FFF2E8] flex items-center leading-5  underline">
                                            LEARN MORE <MdOutlineArrowOutward className="text-[#FFF2E8]" size={20} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

        </div>
     );
}
 
export default BuiltForSection;