import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

const PlayIcon = React.lazy(() => import('../../assets/svgs/mor-assets/caret-forward-circle.svg').then(module => ({ default: module.ReactComponent })));

const HeroSection = ({ handleOpenVideo }: { handleOpenVideo: () => void }) => {
    const navigate = useNavigate();

    return (
        <div className="hero-section bg-gradient-to-b from-[#EAF8EA] to-white flex items-center justify-center h-screen px-6 md:px-10 pt-20 md:pt-40">
            <div className="w-full card-width flex flex-col md:flex-row items-center md:items-start">
                <div className="w-full xl:w-1/2 mt-[4rem] md:mt-[10rem] px-4 md:px-10">

                    <p className="hidden md:block text-[40px] lg:text-[60px] text-center md:text-left text-[#000D0D] tracking-[-2px] lg:tracking-[-3px] font-[700] leading-[50px] lg:leading-[70px] mb-8">
                        Streamlined Compliance Solutions for your Team
                    </p>

                    <p className="block md:hidden text-[32px] text-center text-[#000D0D] tracking-[-1px] font-[700] leading-[38px] mb-6">
                        Streamlined Compliance Solutions for your Team
                    </p>

                    <p className="text-center md:text-left text-[16px] md:text-[20px] lg:text-[24px] leading-relaxed text-[#000D0D]">
                        We make compliance easy. Identify your compliance requirements and manage the process in good time.
                    </p>

                    <div className="flex items-center justify-center md:justify-start gap-4 md:gap-8 mt-8">
                        <button onClick={handleOpenVideo} className="bg-transparent border flex items-center font-[600] gap-2 border-[#228B22] text-[#228B22] px-6 md:px-8 lg:px-10 py-3 rounded">
                            <PlayIcon /> Watch a Demo
                        </button>
                        <button onClick={() => navigate('/login')} className="bg-[#228B22] font-[600] border border-[#228B22] text-white px-6 md:px-8 lg:px-10 py-3 rounded">
                            Get Started
                        </button>
                    </div>
                </div>

                <div className="w-full md:w-1/2 flex items-end justify-center mb-8 md:mb-0">
                    <Suspense fallback={<div>Loading...</div>}>
                        <img
                            className="w-full h-auto object-cover"
                            fetchPriority="high"
                            src="https://norebaseloginstorage.blob.core.windows.net/autocomplyassets/Hero-with-dropshadow.svg"
                            alt="Hero"
                        />
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
