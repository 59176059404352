import { useState, useRef, useEffect } from "react";

import { useCaseData } from "../../utils/data/landingpage";

// COMPONENTS
import FooterComponent from "../../components/shared/FooterComponent";
import VideoPlayerModal from "../../components/modals/landingpage/VideoPlayerModal";
import AutoComplyUseCase from "../../components/modals/landingpage/AutoComplyUseCase";
import BuiltForSection from "./BuiltForSection";
import OnboardingCompliantClient from "./OnboardingCompliantClient";
import MorSection from "./MorSection";
import FaqSection from "./FaqSection";
import BusinessSection from "./BusinessSection";
import LandingPageNavbar from "./LandingPageNavbar";
import HeroSection from "./HeroSection";
import ComplianceCompass from "./ComplianceCompass";
import { useSubmitCompassDataMutation } from "../../store/slices/CompanySlice"; // RTK mutation
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

interface CompassData {
    id: string;
    name: string;
    frequency: string;
    regulator: string;
    description: string;
}
const LandingPageNew = () => {
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [open, setOpen] = useState(false);
    const [compassData, setCompassData] = useState<CompassData[] | null>(null); 
    const [submitCompassForm, { isLoading: isPosting, isSuccess, error }] = useSubmitCompassDataMutation();
    const complianceCompassRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const handleOpenVideo = () => {
        setIsNavbarVisible(false)
        setOpen(true)
    }
    const handleCloseVideo = () => {
        setIsNavbarVisible(true)
        setOpen(false)
    }

    useEffect(() => {
        if(error){
            toast.error(`${error}`)
        }
    }, [error])

    const handleCompassSubmit = async (formData: any) => {
        try {
            const result = await submitCompassForm(formData).unwrap();
            setCompassData(result); 
        } catch (error) {
            console.error("Submission failed", error);
        }
    }

    const handleBackToForm = () => {
        setCompassData(null); 
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const section = searchParams.get('section');

        if (section === 'compass' && complianceCompassRef.current) {
            complianceCompassRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    useEffect(() => {

        if (compassData && complianceCompassRef.current) {
            complianceCompassRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [compassData]);

    return ( 
        <div>
            <VideoPlayerModal open={open} onClose={handleCloseVideo} />
             
            <LandingPageNavbar isVisible={isNavbarVisible} />

            <HeroSection handleOpenVideo={handleOpenVideo} />
            

            <div ref={complianceCompassRef}>
                <ComplianceCompass 
                    onSubmit={handleCompassSubmit}
                    compassData={compassData}
                    isLoading={isPosting}
                    isSuccess={isSuccess}
                    onBackToForm={handleBackToForm}
                />
            </div>

            <BuiltForSection />

            {
                useCaseData.map(item => (
                    <AutoComplyUseCase key={item.title} title={item.title} subtext={item.text} buttonText={item.buttonText} animationUrl={item.animationUrl} />
                ))
            }

            <OnboardingCompliantClient />

            <MorSection />

            <FaqSection />

            <BusinessSection />
            
            <FooterComponent />
        </div>
    );
}
 
export default LandingPageNew;
