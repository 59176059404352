import FaqComponent from "../../components/ui/Faq/FaqComponent";
import { landingFaqData } from "../../utils/data/landingpage";

const FaqSection = () => {
    return ( 
        <section className="w-full md:px-40   pb-10 ">
                <div className="card-width">
                    <div id="#faq"  className="flex flex-col items-center justify-center border-b py-4 pb-10 border-[#dedcdc]">
                        <p className="md:text-[52px] text-[32px] md:text-center text-start text-black font-[600]">Frequently Asked Questions</p>
                    </div>
                    <FaqComponent faqData={landingFaqData} type={'landing'}/>
                </div>
        </section>
     );
}
 
export default FaqSection;