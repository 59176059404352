
import { ObligationType } from "../typings/landingpage";

export const complianceObligations:ObligationType[] = [
    {
      name: "Effortless Obligation Discovery. ",
      description: "Ever been surprised by an obligation in a new country? AutoComply creates a real-time list of all your obligations and keeps that list up to date as obligations change.",
      image: 'discovery',
      containerType: 'image-first'
    },
    {
        name: "Never Miss a Deadline.",
        description: "Set up obligation reminders via email, Slack, or Microsoft Teams to stay on top of due dates for you and your team.",
        image: 'deadline',
        containerType: 'text-first'
    },
    {
      name: "Seamless Compliance Tracking.",
      description: "Manage obligations effortlessly across multiple companies and assign tasks to team members from our user-friendly dashboard.",
      image: 'tracking',
      containerType: 'image-first'
    },
    {
      name: "Document Management System.",
      description: "Auto-archived obligation documents allow convenient retrieval by your team or external collaborators with permission controls and support for custom folders",
      image: 'documents',
      containerType: 'text-first'
    },
    {
    name: "Stay Informed. Avoid Penalties.",
    description: "AutoComply gives you real-time insights into each company's compliance health with reports and risk exposure in the jurisdictions you operate in.",
    image: 'penalties',
    containerType: 'image-first'
    },
    {
      name: "Perform Customer Screenings.",
      description: "Perform PEP and sanction list screenings with real-time monitoring for subjects. AutoComply brings all your compliance tasks into one collaborative tool.",
      image: 'screening',
      containerType: 'text-first'
    },
]

export const landingFaqData = [
    {
      question: "I'm a new player in an industry. How do I figure out compliance for my company?",
      answer: "AutoComply simplifies the process of discovering compliance obligations by providing a real-time list tailored to your company’s operations. This ensures you’re always aware of your requirements, helping you avoid missing deadlines and incurring fines.",
      isOpen: false,
      hasList: false,
      tag: 'dashboard'
    },
    {
      question: "After finding my obligations, what next?",
      answer: "Once AutoComply identifies your obligations, it breaks them down into manageable tasks. We simplify the entire process, from task assignment to document preparation, ensuring you can complete and submit everything to your regulator before the deadline.",
      isOpen: false,
      hasList: false,
      tag: 'settings'
    },
    {
        question: "How does AutoComply help me meet my obligation deadlines?",
        answer: "AutoComply provides automated reminders through various channels such as your dashboard, email, Slack, and MS Teams. These notifications keep you and your team on track, ensuring no deadlines are overlooked.",
        isOpen: false,
        hasList: false,
        tag: 'settings'
    },
    {
        question: "Is my compliance information private?",
        answer: "Yes, AutoComply ensures the privacy of your data. All company information is encrypted, and we adhere to strict data protection standards to keep your information secure.",
        isOpen: false,
        hasList: false,
        tag: 'settings'
    },
    {
        question: "Can I access compliance information for my company now?",
        answer: "Absolutely! Use our Compliance Compass to get a quick overview of the compliance obligations relevant to your company. It's an easy way to understand what you need to focus on right now.",
        isOpen: false,
        hasList: false,
        tag: 'settings'
    },
    {
        question: "What is the cost of AutoComply?",
        answer: "To find out about our pricing, please check our pricing matrix on the pricing page. Contact us directly to create a customized plan tailored to your specific needs.",
        isOpen: false,
        hasList: false,
        tag: 'settings'
    },
    {
        question: "If AutoComply breaks down my obligations into tasks, can I delegate tasks to members of my compliance team?",
        answer: "Yes, you can easily delegate tasks within AutoComply. Our platform allows you to assign specific tasks to team members, facilitating effective collaboration and ensuring timely completion",
        isOpen: false,
        hasList: false,
        tag: 'settings'
    },
    {
        question: "How many companies' compliance can I manage with AutoComply?",
        answer: "The number of companies you can manage depends on your subscription tier. Please consult the pricing matrix on our pricing page(link) for more details.",
        isOpen: false,
        hasList: false,
        tag: 'settings'
    },
    {
        question: "Can AutoComply help me with KYC/KYB?",
        answer: "Yes, AutoComply's user onboarding feature streamlines KYC/KYB processes by collecting user details and verifying individual or business identities, ensuring compliance and security with just a few clicks.",
        isOpen: false,
        hasList: false,
        tag: 'settings'
    },
    {
        question: " Does AutoComply help with PEP and sanction screening?",
        answer: "Yes, AutoComply allows you to easily screen for PEPs and check sanction lists. This feature helps protect your company, ensure regulatory compliance, and perform risk assessments for potential clients.",
        isOpen: false,
        hasList: false,
        tag: 'settings'
    },
]

export const HelpFaqData = [
    {
      question: "How do I add new team members to my AutoComply account?",
      answer: "To add new team members, navigate to the 'Teams' tab on the left menu bar, click 'Add Team Member' at the top right corner, and follow the prompts. You'll need to enter the team member’s details and set their initial role and permissions",
      isOpen: false,
      hasList: false,
      tag: 'Users'
    },
    {
      question: "What roles can I assign to team members in AutoComply?",
      answer: "In AutoComply, you can assign the following roles:   ",
      list: [
            '- Admin: Full access to all features and settings.',
            '- Team Leader: Canmanage team members and access most features but with some restrictions compared to Admin.',
            '- Team Member: Limited access, primarily focused on completing assigned tasks and accessing specific documents or obligations.'
        ],
      isOpen: false,
      hasList: false,
      tag: 'Users'
    },
    {
        question: "How do I set specific permissions for different users on my team?",
        answer: "While adding a new user or managing an existing one, you can select specific permissions that control their access to certain documents, companies, or obligations. This ensures that team members only have access to the information they need for their role",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "Can I restrict access to certain documents or compliance items for specific team members?",
        answer: "Yes, when adding or editing a user’s profile, you can select specific permissions that allow or restrict access to certain documents or compliance items. This helps in maintaining data security and ensuring that sensitive information is only accessible to authorized personnel.",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "What is the difference between an Admin, Team Leader, and Team Member role in AutoComply?",
        answer: "",
        list: [
            '- Admin: Has full access to all features, including adding and removing team members, setting permissions, and accessing all documents and companies.',
            '- Team Leader: Can manage team members and access most features but with some restrictions compared to Admin, such as limited access to certain settings or confidential information.',
            '- Team Member: Has limited access, focused on completing tasks assigned to them and accessing specific documents or obligations they have permission for.'
        ],
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "How can I assign access to specific companies within AutoComply?",
        answer: "During the process of adding a new team member, you can assign them access to specific companies. This is done in the user setup where you choose which companies they should have access to. ",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "Where can I view and manage all team members and their roles?",
        answer: "You can view and manage all team members and their roles within the 'Teams' tab on your AutoComply dashboard. This section provides an overview of all team members, their assigned roles, and their current permissions.",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "What should I consider when assigning roles and permissions to ensure data security?",
        answer: "Consider the following:",
        list: [
            '- Assign the least amount of access necessary for a team member to complete their tasks.',
            '- Restrict access to sensitive or confidential information to only those who absolutely need it.',
            '- Regularly review and update roles and permissions to ensure they align with current responsibilities and organizational changes.'
        ],
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "Can I change a team member's role or permissions after they have been added?",
        answer: "Yes, you can modify a team member’s role or permissions at any time by accessing their profile in the 'Teams' tab. This allows you to adjust their access as their responsibilities within the team change.",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "Is there a limit to the number of team members I can add to my AutoComply account?",
        answer: "The document does not specify a limit, but you should check your AutoComply subscription plan or contact support to verify if there are any restrictions based on your account type.",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "What happens if I remove a team member from AutoComply?",
        answer: "Removing a team member will revoke their access to the AutoComply platform, including all documents and companies they were previously assigned to. Their actions and document history, however, will remain in the system for auditing purposes.",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "How do I ensure a new team member has the correct permissions when added?",
        answer: "During the setup process, after you add a new team member, carefully review the permissions settings to ensure they match the tasks and responsibilities assigned to that individual. You can always adjust these permissions later if needed",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "Can I assign multiple roles to a single user in AutoComply?",
        answer: "No, each user can only have one role at a time (Admin, Team Leader, or Team Member). However, you can tailor their permissions within that role to fit their specific responsibilities.",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "What should I do if I encounter issues while adding or managing team members?",
        answer: "If you encounter issues, kindly reach out via the Help Center and our customer support team will be available to assist you.",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "How does AutoComply ensure that data security is maintained when adding and managing team members?",
        answer: "AutoComply allows you to assign precise roles and permissions to ensure that team members only have access to the information they need. This helps in maintaining high standards of data security and minimizing the risk of unauthorized access to sensitive information.",
        isOpen: false,
        hasList: false,
        tag: 'Users'
    },
    {
        question: "What is a custom obligation in AutoComply?",
        answer: "A custom obligation in AutoComply is a user-defined task or project that your team needs to complete regularly. It is not necessarily tied to regulatory compliance but can include any internal processes that benefit from automation and centralized management, such as daily sales reports, expense voucher processing, or marketing campaign checklists.",
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
    {
        question: "How do I create a custom obligation on AutoComply?",
        answer: "- To create a custom obligation, follow these steps:",
        list: [
            '1. Navigate to the Tasks tab on the left menu bar of your AutoComply dashboard.',
            '2. On the Tasks page, click Add Obligation.',
            '3. On the Obligation Setup page, fill in the details like the obligation name, description, type of compliance, frequency, and filing window.',
            '4. Click Create Obligation to finalize.',
            '5. After your obligation has been created, you can proceed to create tasks for this obligation.'
        ],
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
    {
        question: " What information do I need to provide when setting up a custom obligation?",
        answer: "- You will need to provide:",
        list: [
            '1. Obligation Name: A title for the obligation or project (e.g., "WHT Returns" or "Complete Office Move").',
            '2. Obligation Description: A brief explanation to ensure all team members understand the task.',
            '3. Type of Compliance: Choose whether it’s a regulatory obligation or a general compliance task.',
            '4. Frequency: How often the obligation needs to be completed (e.g., weekly, monthly).',
            '5. Filing Window: The time frame before the deadline when you want to be reminded to start working on the obligation.'
        ],
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
    {
        question: "Can I create a custom obligation for non-compliance tasks?",
        answer: "Yes, AutoComply allows you to create custom obligations for non-compliance-related tasks, which can help streamline internal processes and improve team collaboration on various projects.",
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
    {
        question: "What is ‘Frequency’ for a custom obligation?",
        answer: "When setting up a custom obligation, you can select how often it needs to be completed. Options may include weekly, monthly, quarterly, or any other period that fits the nature of the task",
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
    {
        question: "What is the 'Completion timeline' when creating a custom obligation?",
        answer: "The completion timeline is the amount of time in days, before the obligation deadline that you or team require to organize and submit the data need to file an obligation. Obligations will be visible to your team from the first day of the completion timeline is reached up until the obligation deadline is reached.",
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
    {
        question: "What should I consider when creating a custom obligation in AutoComply?",
        answer: "Ensure that the obligation name and description are clear so that all team members understand what is required. Also, carefully choose the type of compliance, frequency, and filing window to match the needs of your task or project.",
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
    {
        question: "How can custom obligations help improve my team’s efficiency?",
        answer: "By automating reminders and centralizing task management, custom obligations help your team stay on track with regular tasks and reduce the chances of missing important steps or deadlines.",
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
    {
        question: "Can I edit a custom obligation after it has been created?",
        answer: "- Yes, you can edit a custom obligation after it has been created. To do this, follow these steps:",
        list: [
            '1. Go to the Tasks page on your AutoComply dashboard.',
            '2. Click on All Obligations.',
            '3. Navigate to Custom Obligations to see the list of obligations you have created.',
            '4. Find the obligation you want to edit.',
            '5. Click on Manage next to the obligation.',
            '6. Select Edit to make changes to the obligation.'
        ],
        isOpen: false,
        hasList: false,
        tag: 'Custom Obligations'
    },
]

export const useCaseData = [
    {
        title: 'Effortless Obligation Discovery',
        text: 'We create and maintain a real-time list of obligations for your company.',
        buttonText: 'Discover your obligations',
        animationUrl: 'https://norebaseloginstorage.blob.core.windows.net/autocomplyassets/Animation 1.mp4'
    },
    {
        title: 'Compliance Tracking and Reminders',
        text: 'Receive timely notifications on your dashboard and through your preferred channels - Slack, email and MS Teams - to effortlessly complete obligations.',
        buttonText: 'Track your compliance',
        animationUrl: 'https://norebaseloginstorage.blob.core.windows.net/autocomplyassets/Animation 2.mp4'
    },
    
    {
        title: 'Seamless Team Collaboration',
        text: 'Add team members to AutoComply and collaborate to meet obligation deadlines.',
        buttonText: 'Collaborate with your team ',
        animationUrl: 'https://norebaseloginstorage.blob.core.windows.net/autocomplyassets/Animation 3.mp4'
    },
    {
        title: 'Multi-Jurisdiction Support',
        text: 'Manage compliance for your global companies from a single dashboard.',
        buttonText: 'Stay complaint across borders',
        animationUrl: 'https://norebaseloginstorage.blob.core.windows.net/autocomplyassets/Animation 4.mp4'
    },
]

export const morDataList = [
  {
    name: "Payment Localization",
    description: "Start selling how your customers prefer to buy, with local languages, currencies, and payment methods.",
    image: 'payment',
  },
  {
    name: "Sales Tax Compliance",
    description: "Never worry about global sales tax again, we’ll handle each and every local tax obligations for you automatically.",
    image: 'sales',
  },
  {
    name: "Fraud Prevention",
    description: "Mitigate your risk and avoid unnecessary penalties through our fraud protection and prevention infrastructure.",
    image: 'fraud',
  },
  {
    name: "Access Untapped Markets",
    description: "Penetrate new regions and maximize your revenue potential with our market insights and tailored strategies.",
    image: 'markets',
  },
  {
    name: "Dunning Management",
    description: "Minimize revenue loss, streamline payment recovery processes and maintain positive customer relationships",
    image: 'management',
  },
  {
    name: "Reliable Support",
    description: "We offer comprehensive assistance from onboarding and integration to ongoing maintenance and optimization.",
    image: 'support',
  },
  
]