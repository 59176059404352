import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import CompassDocImage from '../../assets/svgs/mor-assets/compass-new-doc-image.webp';
import * as yup from 'yup';
import InputGroup from '../../components/ui/form/InputGroup';
import { useGetAllLookUpQuery } from '../../store/slices/LookupSlice';
import { IndustryTypes } from '../../utils/typings/lookup';
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Loader from '../../components/shared/Loader';
import CompassResult from './CompassResult';
import { IoIosArrowBack } from 'react-icons/io';


interface CompassFormData {
    countryCode: string,
    industryIds: string[],
    countryName?: string,
    firstName: string,
    emailAddress: string,
};

interface CompassData {
    id: string;
    name: string;
    frequency: string;
    regulator: string;
    description: string;
}
interface ComplianceCompassProps {
    onSubmit: (data: CompassFormData) => void;
    compassData: CompassData[] | null; 
    isLoading: boolean;
    isSuccess: boolean;
    onBackToForm: () => void;
}

const AutoComplyGrayLogo = React.lazy(() => import('../../assets/svgs/mor-assets/autocomply-gray-logo.svg').then(module => ({ default: module.ReactComponent })));

const ComplianceCompass: React.FC<ComplianceCompassProps> = ({ onSubmit, compassData, isLoading, isSuccess, onBackToForm }) => {
    const [selectedIndustryType, setSelectedIndustryType] = useState<IndustryTypes[]>([]);
    const { data: AllLookUp, isLoading: isLoadingLookup } = useGetAllLookUpQuery();
    const [countryNameError, setCountryNameError] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
    const checkedIcon = <CheckBoxIcon fontSize="large" />;

    const schema = yup.object().shape({
        countryCode: yup.string().required('Country is required'),
        countryName: yup.string(),
        industryIds: yup.array().min(1, 'At least one industry must be selected').required(),
        firstName: yup.string().required('First name is required'),
        emailAddress: yup.string().matches(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
            'Invalid email address'
        ).required('Email is required'),

    });

    const defaultValues = {
        countryCode: '',
        industryIds: [],
        countryName: '',
        firstName: '',
        emailAddress: '',
    };

    const { control, handleSubmit, setValue,watch, reset, formState: { errors } } = useForm<any>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues,
        shouldUnregister: true,
    });

    const countryCode = watch('countryCode');
    const countryName = watch('countryName');

    const countries = [
        { value: "GH", name: "Ghana" },
        { value: "KE", name: "Kenya" },
        { value: "NG", name: "Nigeria" },
        { value: "GB", name: "United Kingdom" },
        { value: "US", name: "United States" },
        { value: "RW", name: "Rwanda" },
        { value: "ZA", name: "South Africa" },
        { value: "Other", name: "Other" },
    ];

    

    const handleIndustrySelection = useCallback((data: IndustryTypes[]) => {
        setSelectedIndustryType(data);
        const industryIds = data.map((industry) => industry.id);
        setValue('industryIds', industryIds); 
    }, [setValue]);

    useEffect(() => {
        if (compassData) {
            reset();
        }
    }, [compassData, reset]);

    useEffect(() => {
        if (hasSubmitted && countryName) {
            setCountryNameError('');
        }
    }, [countryName, hasSubmitted]);

    const onSubmitHandler = (data: CompassFormData) => {
        setHasSubmitted(true);
        if (data.countryCode === 'Other' && !data.countryName) {
            setCountryNameError("Please provide a country name if 'Other' is selected.")
            return; 
        }
        setCountryNameError('');
        onSubmit(data); 
    };

    const handleBackToForm = () => {
        reset();
        setSelectedIndustryType([]);
        onBackToForm()
    };

    return (
        <section className="bg-darkGreen flex items-center justify-center px-10 md:px-40 py-40">
            <div className="w-full card-width !mx-0 md:[2.5rem] flex flex-col lg:gap-[6rem] items-start justify-start h-full md:flex-row">
                <div className="w-full xl:w-1/2">
                    <p className="md:text-[48px] text-[26px] text-start text-white font-[600]">Compliance Compass</p>
                    <p className="text-[18px] md:text-[20px] text-start text-[#ffffffcb] mt-[16px]">
                        Curious about your compliance needs? Tell us more about your business and we'll help you identify what should be on your radar.
                    </p>
                    <div className="hidden md:block  w-[80%] my-16 mx-auto ">
                        <img src={CompassDocImage} alt="" className="w-full h-full" />
                    </div>
                </div>

                <div className="w-full md:w-1/2">
                    {isLoadingLookup && <Loader loaderType="dots" />}
                    {!compassData && !isLoadingLookup && (
                        <form className="bg-transparent w-full md:mt-0 mt-[40px]" onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className="form-group mt-5">
                                <label className="text-white mb-5 block" htmlFor="firstName">What is your business name?</label>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field }) => (
                                        <InputGroup
                                        {...field}
                                            ref={null}
                                            error={errors?.firstName}
                                            type="text"
                                            placeholder="What is your business name?"
                                            label=""/>
                                    )}
                                />
                            </div>
                            <div className="form-group mt-5">
                                <label className="text-white mb-5 block" htmlFor="emailAddress">What is your email address?</label>
                                <Controller
                                    name="emailAddress"
                                    control={control}
                                    render={({ field }) => (
                                        <InputGroup
                                        {...field}
                                        ref={null}
                                        error={errors?.emailAddress}
                                        type="text"
                                        placeholder="What is your email address?"
                                        label=""
                                        />
                                    )}
                                />
                            </div>
                            <div className="form-group mt-5">
                                <label className="text-white mb-5 block" htmlFor="countryCode">Which country does your business operate in?</label>
                                <Controller
                                    name="countryCode"
                                    control={control}
                                    render={({ field }) => (
                                        <InputGroup
                                        {...field}
                                        ref={null}
                                        error={errors?.countryCode}
                                        type="select"
                                        options={countries}
                                        label=""
                                        placeholder='Select country'
                                        />
                                    )}
                                />
                            </div>

                            {
                                countryCode === "Other" && 
                                (
                                    <div className="form-group mt-5">
                                        <label className="text-white mb-5 block" htmlFor="countryName">If 'Other' please enter the country here</label>
                                        <Controller
                                            name="countryName"
                                            control={control}
                                            render={({ field }) => (
                                                <InputGroup
                                                    {...field}
                                                    ref={null}
                                                    error={errors?.countryName}
                                                    type="text"
                                                    placeholder="What is the name of the country?"
                                                    label=""
                                                />
                                            )}
                                        />
                                        {countryNameError && (
                                            <p className="text-[#EF4C49] text-[12px] mt-3">{countryNameError}</p>
                                        )}
                                    </div>
                                )
                            }

                            <div className="form-group mt-5">
                                <label className="text-white mb-5 block" htmlFor="industry">What industry does your business operate in?</label>
                                {AllLookUp?.industryTypes?.length && (
                                    <Autocomplete
                                    className="flex flex-col gap-5 rounded-[4px]"
                                    sx={{
                                        '& .MuiAutocomplete-tag': { backgroundColor: '#E5F1E5' },
                                        '& .MuiChip-deleteIcon': { color: '#228B22' },
                                    }}
                                    multiple
                                    id="industry"
                                    options={AllLookUp?.industryTypes}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => handleIndustrySelection(newValue)}
                                    disableCloseOnSelect
                                    value={selectedIndustryType}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                sx={{ '&.Mui-checked': { color: '#228B22' } }}
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} className="mt-4 bg-white px-[2.5rem] rounded-[4px]" placeholder="Select Industries" sx={{ '& ::placeholder': { fontSize: '14px', marginTop: '2px', color: '#737373', fontFamily: 'Manrope' } }} />
                                    )}
                                />
                                )}
                                {errors.industryIds && typeof errors.industryIds.message === 'string' && (
                                    <p className="text-[#EF4C49] text-[12px] ">At least one industry must be selected</p>
                                )}
                            </div>
                            <button type="submit" className={`w-full mt-10 py-5 px-20 font-[600]  ${ isLoading ?  'bg-[#C3C4CD]' : 'bg-primaryGreen'} flex items-center justify-center text-white rounded-[4px]`}>
                                { isLoading ? <Loader loaderType='dots' type='button' /> : 'Find your Compliance'}
                            </button>
                        </form>
                    )}
                    {compassData&& compassData?.length > 0 && isSuccess && (
                        <div>
                            <button onClick={handleBackToForm} className="text-white mt-[40px] md:mt-[10px] flex items-center justify-center  text-[18px] md:text-[20px]  mb-4 ">
                                <IoIosArrowBack className="text-white" /> Return to the information form
                            </button>
                            <CompassResult compassData={compassData} />
                        </div>
                    )}

                    {compassData?.length === 0 && isSuccess && !isLoading && (
                        <div className='w-full mt-24    '>
                            <button onClick={handleBackToForm} className="text-white text-[18px] mt-[10px] flex items-center justify-center md:text-[20px]   mb-4 ">
                                <IoIosArrowBack className="text-white" />   Return to the information form
                            </button>
                            <div className='w-full bg-white p-10 min-h-[200px] rounded-[4px] '>
                                <p className="text-black text-[24px] font-[700] tracking-[-1px] mb-3">Your all-in-one Obligation Discovery Tool.</p>
                                <p className="text-[20px] mb-5">We’re continually expanding our database to cover all industries and regions. We’ll contact you soon with your unique compliance requirements.</p>
                                <div className='w-full flex items-end justify-end mb-2'>
                                    <AutoComplyGrayLogo />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ComplianceCompass;
