import React from 'react';
import { useNavigate } from 'react-router-dom';
const AutoComplyLogo = React.lazy(() => import('../../assets/svgs/green-logo.svg').then(module => ({ default: module.ReactComponent })));


const LandingPageNavbar = ({ isVisible }: { isVisible: boolean }) => {
    const navigate = useNavigate()

    return (
      <nav
        className={`fixed ${isVisible ? 'block' : 'hidden'} md:px-40 top-0 left-0 w-full z-50 flex items-center justify-center  right-0 py-6 transition-all duration-300 ease-in-out bg-white`}
      >
        <div className='card-width flex items-center justify-between  w-full'>
          <a href="#" className="text-xl font-bold text-gray-800">
            <AutoComplyLogo className='w-full h-full' />
          </a>
          <button onClick={() => navigate('/login')} className="bg-[#228B22] font-[600] border border-[#228B22] text-white px-10 py-3 rounded">
            Get Started
          </button>
        </div>
      </nav>
    );
  };
  
  export default LandingPageNavbar;
  