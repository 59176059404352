import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios,{ baseUrl }  from '../../utils/AxiosConfig';
import unAuthenticatedAxios from 'axios'
import { ComplianceItemCalendar, Company, CompanyDetails,Permissions, CompanyLegalStructure, CreateCompanyPayloadType, UpdateCompanyDetails, UpdateCompanyLocation } from '../../utils/typings/company';
import { complianceApi } from './ComplianceSlice';
import { DashboardStatistics, StatisticsPayloadType } from '../../utils/typings/compliance';
import { toast } from 'react-toastify';
import { CalendarViewDataType } from "../../utils/typings/calendar";



//------------------------------------------------------RTK-Query----------------------------------------------------//
export const companyApi = createApi({
    reducerPath: 'companyApi', 
    tagTypes: ['CompanyList', 'CompanyData', 'ActiveCompanyDetails', 'ActiveCompany','DasboardStatistics', 'Permissions'],
    keepUnusedDataFor: 30 * (60 * 1000), //cache data for 30 minutes
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        getAllCompanies: builder.query<Company[], void>({
            queryFn: () => {
                return axios.get(`api/Companies/get-all-companies`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled, getState }) {
                // `onStart` side-effect
                // console.log('query argument is', queryArg)
                // console.log(getState())
                try {
                    const { data } = await queryFulfilled
                    // `onSuccess` side-effect
                    const activeCompanyIdFromSession = sessionStorage.getItem('activeCompanyId');
                    if(data?.length > 0){
                        if(!activeCompanyIdFromSession){
                            sessionStorage.setItem("activeCompanyId", data[0].id);
                            dispatch(companyApi.util.upsertQueryData('getActiveCompany', undefined, data[0].id))
                        }
                        else {
                            const activeCompany = data.filter((company: Company) => company?.id === activeCompanyIdFromSession)[0];
                            if(activeCompany){
                                sessionStorage.setItem("activeCompanyId", activeCompanyIdFromSession);
                                dispatch(companyApi.util.upsertQueryData('getActiveCompany', undefined, activeCompanyIdFromSession)) //invalidate existing cache
                            }
                            else{
                                sessionStorage.setItem("activeCompanyId", data[0].id);
                                dispatch(companyApi.util.upsertQueryData('getActiveCompany', undefined, data[0].id)) //invalidate existing cache
                            }
                        }
                        //dispatch(otherApi.util.invalidateTags(['ActiveCompany'])) //invalidate query in another api
                        //console.log(data[0])
                        
                    }
                } catch (err) {
                    // `onError` side-effect
                    //console.log('data fetch has failed:', err)
                }
            },
            providesTags: () => [{ type: 'CompanyList' }]
        }),
        getActiveCompanyDetails: builder.query<Company, Company[]>({
            queryFn(companyList){
                const activeCompanyIdFromSession = sessionStorage.getItem('activeCompanyId');
                if(activeCompanyIdFromSession){
                    let selectedCompany = companyList?.filter((company:Company) => company?.id === activeCompanyIdFromSession)[0]
                    return {
                        data: selectedCompany
                    }
                }
                return {
                    error: {
                        status: 400,
                        statusText: '',
                        data: 'No company details found',
                    },
                }
            },
            providesTags: () => [{ type: 'ActiveCompanyDetails' }]
        }),
        getActiveCompany: builder.query<string, void>({
            queryFn(){
                const activeCompanyIdFromSession = sessionStorage.getItem('activeCompanyId')
                if(activeCompanyIdFromSession){
                    return {
                        data: activeCompanyIdFromSession
                    }
                }
                return {
                    error: {
                        status: 400,
                        statusText: '',
                        data: 'No company id found',
                    },
                }
            },
            providesTags: () => [{ type: 'ActiveCompany' }]
        }),
        setActiveCompany: builder.mutation<string, string>({
            queryFn: (companyId) => {
                if(companyId){
                    sessionStorage.setItem("activeCompanyId", companyId);
                    return {
                        data: 'Company Id stored'
                    }
                }
                return {
                    error: {
                        status: 400,
                        statusText: '',
                        data: 'Company ID could not be stored',
                    },
                }
            },
            invalidatesTags: ['ActiveCompany', 'ActiveCompanyDetails']
        }),
        createCompany: builder.mutation<{companyId: string}, CreateCompanyPayloadType>({
            queryFn: (data) => {
                return axios.post('api/Companies', data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(complianceApi.util.invalidateTags(['AllObligations', 'PriorityObligations'])) //invalidate query in another api
                    
                } catch (err) {
                }
            },
            invalidatesTags: ['CompanyList', 'ActiveCompany', 'ActiveCompanyDetails']
        }),
        submitCompassData: builder.mutation<any, {country: string, industry: string, name: string, email: string}>({
            queryFn: (data) => {
                return unAuthenticatedAxios.post(`${baseUrl}/api/ComplianceCompass`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
        }),
        createCompanyApiKey: builder.mutation<{companyId: string}, {companyId: string}>({
            queryFn: (data) => {
                return axios.post('api/CompanyApiKey/create', data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(complianceApi.util.invalidateTags(['AllObligations', 'PriorityObligations'])) //invalidate query in another api
                    
                } catch (err) {
                }
            },
            invalidatesTags: ['CompanyList', 'ActiveCompany', 'ActiveCompanyDetails']
        }),
        revokeCompanyApiKey: builder.mutation<{companyId: string}, {companyId: string}>({
            queryFn: (data) => {
                return axios.delete(`api/CompanyApiKey/Remove/${data.companyId}`)
                    .then((response) => { 
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(complianceApi.util.invalidateTags(['AllObligations', 'PriorityObligations'])) //invalidate query in another api
                    
                } catch (err) {
                }
            },
            invalidatesTags: ['CompanyList', 'ActiveCompany', 'ActiveCompanyDetails']
        }),
        getDashboardStatistics: builder.query<DashboardStatistics, StatisticsPayloadType>({
            queryFn: (payload) => {
                return axios.get(`api/obligationStatistics/GetTrackedObligationsAndTasks?emailAddress=${payload.emailAddress}&startDate=${payload.startDate}&endDate=${payload.endDate}`)
                .then((response) => {
                    return {
                        data: response.data.result
                    }
                })
                .catch(({ response }) => {
                    return Promise.reject(new Error(response.data))
                });
            },
            providesTags: () => [{ type: 'DasboardStatistics' }]
        }),
        getDashboard: builder.query<CompanyDetails, string>({
            queryFn: (companyId) => {
                return axios.get(`api/Companies/dashboard/${companyId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'CompanyData' }]
        }),
        getPermissions: builder.query<Permissions, void>({
            queryFn: () => {
                return axios.get(`api/Users/permissions`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Permissions' }]
        }),
        getCalendarDashboard: builder.query<ComplianceItemCalendar[], string>({
            queryFn: (whereCondition) => {
                return axios.get(`api/Companies/dashboard-compliance/${1}/${50}/${whereCondition}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
        }),
        getCalendarViewData: builder.query<CalendarViewDataType[], string>({
            queryFn: (whereCondition) => {
                return axios.get(`api/Companies/v2/dashboard-compliance/${1}/${50}/${whereCondition}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
        }),
        getCompanyTypesByCountry: builder.query<{id:string, name: string}[], string>({
            queryFn: (countryCode) => {
                return axios.get(`api/countries/company-types/${countryCode}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
        }),
        updateCompanyDetails: builder.mutation<void, { companyId: string, payload: UpdateCompanyDetails}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-details/update`, data.payload)
                    .then((response) => {
                        toast.success('Company details updated')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        addCompanyLocation: builder.mutation<void, { companyId: string, payload: UpdateCompanyLocation}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-location/add`, data.payload)
                    .then((response) => {
                        toast.success('Company location details added')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        deleteCompanyLocation: builder.mutation<void, { companyId: string, locationId: string}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-location/${data.locationId}/remove`)
                    .then((response) => {
                        toast.success('Company location detail deleted successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        updateCompanyLocation: builder.mutation<void, { companyId: string, payload: UpdateCompanyLocation}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-location/update`, data.payload)
                    .then((response) => {
                        toast.success('Company location updated')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        addCompanyShareholders: builder.mutation<void, { companyId: string, payload: CompanyLegalStructure}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-shareholder/add`, data.payload)
                    .then((response) => {
                        toast.success('Company shareholder added successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        deleteCompanyShareholder: builder.mutation<void, { companyId: string, shareholderId: string}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-shareholder/${data.shareholderId}/remove`)
                    .then((response) => {
                        toast.success('Company shareholder detail deleted successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        updateCompanyShareholder: builder.mutation<void, { companyId: string, payload: CompanyLegalStructure}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-shareholder/update`, data.payload)
                    .then((response) => {
                        toast.success('Company shareholder updated')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        addCompanyDirectors: builder.mutation<void, { companyId: string, payload: CompanyLegalStructure}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-director/add`, data.payload)
                    .then((response) => {
                        toast.success('Company director added successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        deleteCompanyDirector: builder.mutation<void, { companyId: string, directorId: string}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-director/${data.directorId}/remove`)
                    .then((response) => {
                        toast.success('Company director detail deleted successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        updateCompanyDirector: builder.mutation<void, { companyId: string, payload: CompanyLegalStructure}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-director/update`, data.payload)
                    .then((response) => {
                        toast.success('Company director updated')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        addCompanyBoardComposition: builder.mutation<void, { companyId: string, payload: CompanyLegalStructure}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-board-composition/add`, data.payload)
                    .then((response) => {
                        toast.success('Board personnel added successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        deleteCompanyBoardComposition: builder.mutation<void, { companyId: string, boardId: string}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-board-composition/${data.boardId}/remove`)
                    .then((response) => {
                        toast.success('Company board deleted successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        updateCompanyBoardComposition: builder.mutation<void, { companyId: string, payload: CompanyLegalStructure}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/${data.companyId}/company-board-composition/update`, data.payload)
                    .then((response) => {
                        toast.success('Board personnel updated')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['CompanyData']
        }),
        refreshToken: builder.mutation<void, void>({
            queryFn: async () => {
                try {
                    const refreshTokenData = localStorage.getItem('refreshToken');
                    const payload = { refreshToken: refreshTokenData };
                    const response = await fetch(`${process.env.REACT_APP_VAULT_API_URL}/Account/refresh-token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    if (!response.ok) {
                        throw new Error(await response.text()); // Throw error with details
                    }
      
                    const data = await response.json();
                    localStorage.setItem('refreshToken', data.refreshToken);
      
                    return { data };
                } catch (error) {
                    toast.error('Failed to refresh token: ' + error.message);
                    throw error;
                }
            }
        }),
    })
})


export const { 
    useGetAllCompaniesQuery, useCreateCompanyApiKeyMutation, useRevokeCompanyApiKeyMutation, useLazyGetAllCompaniesQuery, useGetActiveCompanyQuery, useGetActiveCompanyDetailsQuery, useGetPermissionsQuery,
    useSetActiveCompanyMutation, useCreateCompanyMutation,
    useGetDashboardQuery, useGetCalendarDashboardQuery, useGetCompanyTypesByCountryQuery,
    useUpdateCompanyDetailsMutation, useAddCompanyLocationMutation, useAddCompanyShareholdersMutation,
    useAddCompanyDirectorsMutation, useAddCompanyBoardCompositionMutation, useDeleteCompanyLocationMutation,
    useDeleteCompanyShareholderMutation, useDeleteCompanyDirectorMutation, useDeleteCompanyBoardCompositionMutation,
    useUpdateCompanyLocationMutation, useUpdateCompanyShareholderMutation, useUpdateCompanyDirectorMutation, 
    useUpdateCompanyBoardCompositionMutation, useGetDashboardStatisticsQuery, useLazyGetDashboardStatisticsQuery, 
    useGetCalendarViewDataQuery, useRefreshTokenMutation, useSubmitCompassDataMutation,
} = companyApi

export const refreshToken = async () => {
    try {
      const refreshTokenData = localStorage.getItem('refreshToken');
      const payload = { refreshToken: refreshTokenData };
      const url = `${process.env.REACT_APP_BASE_API_URL}Account/refresh-token`;
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      
      localStorage.setItem('refreshToken', data.refreshToken);
  
      return data;
    } catch (error) {
        toast.error('Failed to refresh token: ' + error.message);
    }
  };