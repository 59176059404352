import { Link } from "react-router-dom";
import MorImage from '../../assets/svgs/mor-assets/mor-dashboard-img.svg'



const MorSection = () => {
    return ( 
        <section className="mb-20">
                <div className="bg-[#F2F8F2]">
                    <div className=' card-width flex flex-col-reverse md:flex-row items-center md:py-40 py-10'>
                    <div className='w-full mt-10 md:mt-0  md:w-1/2 md:px-[6rem] flex-wrap px-[10px]'>
                            <div className='bg-[#E5F1E5] p-[6rem] w-full h-full'>
                                <img src={MorImage} alt="MoR dashboard" className='w-full h-full'/>
                            </div>
                    </div>
                    <div className='w-full  md:w-1/2 md:px-[6rem] md:mt-0 mt-20 flex-wrap px-[10px]'>
                            <p className="text-[16px]  text-[#228B22] ">
                                MERCHANT OF RECORD (MoR)
                            </p>
                            <p className="md:text-[40px] text-[32px] text-[#000D0D] tracking-[-1px] font-[600] md:leading-[50px]  mb-8">
                                All your global sales tax and compliance handled
                            </p>
                            <p className=' max-w-[90%] text-left md:text-[16px] font-[400] text-[16px] mb-16 text-[#000D0D]'>
                                We serve as your trusted global Merchant of Record (MoR) by providing comprehensive infrastructure and solutions that enable your business to reach a wider market faster.
                            </p>

                            <Link to='/MoR' className='px-10 py-4 bg-[#228B22] font-[600] rounded text-[#E5F1E5]'>
                                Get Started
                            </Link>
                    </div>
                    </div>
                </div>
            </section>
     );
}
 
export default MorSection;