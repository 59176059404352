import React from "react";
import { ButtonType } from "../../utils/typings/shared";
import Loader from "../shared/Loader";


function Button({ disabled = false, className, outline=false, loading=false, inverted=false, onClick, type='button', children, prepend, append, icon }: Readonly<ButtonType>) {

    const outlineClass = outline ? 'border border-primaryGreen text-primaryGreen px-12' : ''
    const invertedClass = inverted ? 'bg-primaryGreen text-primaryGreen px-8' : ''
    const disabledClass = disabled ? 'bg-[#228b225a] text-white px-8' : ''
    const regularClass = !inverted && !disabled && !outline ? 'bg-primaryGreen text-white px-12' : ''

    return (
        <button onClick={onClick} disabled={disabled} type={type} className={` ${disabledClass} ${className} ${outlineClass} ${invertedClass} ${regularClass} flex items-center gap-3 py-5 rounded-md `}>
			{(icon || prepend) && (
				<span className="prepend-icon">{icon ?? prepend}</span>
			)}
			{ loading ? <Loader type='button'/> : children}
			{append && <span className="append-icon">{append}</span>}
        </button>
    )
}

export const GreyButton = ({ loading, children,onClick, className }:ButtonType) => {
    return (
        <button onClick={onClick} type='button' className={`${className} bg-[#9196971A] text-[#8A8B94] px-24 py-5`}>
            { loading ? <Loader type='button'/> : children}
        </button>
    )
}

export const LightGreenButton = ({ loading, children,onClick, className, prepend, icon }:ButtonType) => {
    return (
        <button onClick={onClick} type='button' className={`${className} bg-[#E5F1E5] text-primaryGreen px-24 py-5`}>
            { loading ? <Loader type='button'/> : children}
        </button>
    )
}

export const OutlineButton = ({ loading, children,onClick, className }:ButtonType) => {
    return (
        <button onClick={onClick} type='button' className={`${className} bg-white text-primaryGreen border border-primaryGreen px-24 py-5 rounded-md`}>
            { loading ? <Loader type='button'/> : children}
        </button>
    )
}

export default Button;
