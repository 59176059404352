import axios from "../../utils/AxiosConfig";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { ComplianceItem, ComplianceQueryType, OptionsType, ObligationInterface } from "../../utils/typings/backoffice";
import { taskApi } from "./TasksSlice";


export const obligationApi = createApi({
    reducerPath: 'obligationApi',
    tagTypes: ['Custom','Country','Frequency','Input','AllMonitoring', 'ScreeningStats', 'MonitoringStatus'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        getAllObligations: builder.query<ComplianceItem[], ComplianceQueryType>({
            queryFn: (params) => {
                const url = `api/CustomCompliance/Company/${params.companyId}`;
                const queryParams = `?page=${params.page}&limit=${params.limit}`;
                return axios.get(url + queryParams)
                    .then((response) => {
                        return {
                            data: response.data.result.items
                        }
                    })
                    .catch(({ response }) => {
                        return {
                            error: {
                                status: response.status,
                                data: response.data,
                                statusText: response.statusText
                            }
                        }
                    });
            },
            providesTags: () => [{ type: 'Custom' }]
        }),
        getCountryBasedObligations: builder.query<ComplianceItem[], ComplianceQueryType>({
            queryFn: (params) => {
                const url = `api/companies/Company/${params.companyId}`;
                const queryParams = `?page=${params.page}&limit=${params.limit}`;
                return axios.get(url + queryParams)
                    .then((response) => {
                        return {
                            data: response.data.result.items
                        }
                    })
                    .catch(({ response }) => {
                        return {
                            error: {
                                status: response.status,
                                data: response.data,
                                statusText: response.statusText
                            }
                        }
                    });
            },
            providesTags: () => [{ type: 'Country' }]
        }),
        getObligation: builder.query<ObligationInterface, {companyId: string, complianceId: string}>({
            queryFn: (data) => {
                return axios.get(`api/CustomCompliance/company/${data.companyId}/compliance/${data.complianceId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return {
                            error: {
                                status: response.status,
                                data: response.data,
                                statusText: response.statusText
                            }
                        }
                    });
            },
            providesTags: () => [{ type: 'Custom' }]
        }),
        postObligation: builder.mutation<ObligationInterface, any>({
            queryFn: (data) => {
                const url = `api/CustomCompliance/company/${data.companyId}/compliance/${data.complianceId}`;
                console.log('post obligation triggered')
                return axios.post(url, data.task)
                    .then((response) => {
                        console.log('response', response)
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return {
                            error: {
                                status: response.status,
                                data: response.data,
                                statusText: response.statusText
                            }
                        }
                    });
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(taskApi.util.invalidateTags(['Tasks']))
                    
                } catch (err) {
                }
            },
            invalidatesTags: ['Custom']
        }),
        createCustomCompliance: builder.mutation<void, void>({
            queryFn: (data) => {
                return axios.post(`api/CustomCompliance`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return {
                            error: {
                                status: response.status,
                                data: response.data,
                                statusText: response.statusText
                            }
                        }
                    });
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(taskApi.util.invalidateTags(['Tasks']))
                    
                } catch (err) {
                }
            },
            invalidatesTags: ['Custom']
        }),
        uploadTemplate: builder.mutation<any, FormData>({
            queryFn: (data) => {
                const uploadConfig = {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                }
                return axios.post(`api/CustomCompliance/upload-template`, data, uploadConfig)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return {
							error: {
								status: response.status,
								data: response.data,
								statusText: response.statusText
							}
						}
					});
            },
        }),
        getObligationFrequencies: builder.query<OptionsType[], void>({
            queryFn: () => {
                return axios.get(`api/countries/frequencies`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return {
							error: {
								status: response.status,
								data: response.data,
								statusText: response.statusText
							}
						}
					});
            },
            providesTags: () => [{ type: 'Frequency' }]
        }),

        getInputTypes: builder.query<{id: string, name:string}[], void>({
            queryFn: () => {
                return axios.get(`api/CustomCompliance/input-types`)
                    .then((response) => {
                        return {
                            data: response.data
                        }
                    })
                    .catch(({ response }) => {
						return {
							error: {
								status: response.status,
								data: response.data,
								statusText: response.statusText
							}
						}
					});
            },
            providesTags: () => [{ type: 'Input' }]
        }),

        toggleComplianceTracking: builder.mutation<void, {companyId: string, complianceId: string}>({
            queryFn: (data) => {
                return axios.post('api/Companies/toggle-obligation-tracking', data)
                    .then((response) => {
                        toast.success(response.data.result.message)
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return {
                            error: {
                                status: response.status,
                                data: response.data,
                                statusText: response.statusText
                            }
                        }
                    });
            },
            invalidatesTags: ['Country']
        }),
    })
})

export const {
    useGetAllObligationsQuery,
    useGetCountryBasedObligationsQuery,
    useGetInputTypesQuery,
    useToggleComplianceTrackingMutation,
    useGetObligationQuery,
    usePostObligationMutation,
    useCreateCustomComplianceMutation,
    useGetObligationFrequenciesQuery,
    useUploadTemplateMutation
} = obligationApi

