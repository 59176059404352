
import Accordion from "./Accordion";

type PropsType ={
    faqData: {
        question: string,
        answer: string,
        isOpen?: boolean;
        list?: string[];
        hasList?: boolean;
        tag?: string;
    }[],
    type: string
}

const FaqComponent = ({faqData, type}: PropsType) => {
  
    return ( 
        <div className=" card-width  rounded lg">
            {faqData.map(faq => {
                return (
                    <Accordion key={faq.question} list={faq.list}  title={faq.question} answer={faq.answer} type={type}  /> 
                )
            })}
        </div>
     );
}
 
export default FaqComponent;