
import React from 'react';
import { ReactComponent as Logo } from '../../assets/svgs/mor-assets/green-logo.svg'
const TwitterIcon = React.lazy(() => import('../../assets/svgs/mor-assets/twitter-footer-icon.svg').then(module => ({ default: module.ReactComponent })));
const InstagramIcon = React.lazy(() => import('../../assets/svgs/mor-assets/instagram.svg').then(module => ({ default: module.ReactComponent })));
const LinkedInIcon = React.lazy(() => import('../../assets/svgs/mor-assets/linkedin-footer-icon.svg').then(module => ({ default: module.ReactComponent })));

const FooterComponent = () => {
    return ( 
        <footer className="w-full md:px-40  mt-20  py-20 ">
        <div className='card-width flex justify-center   items-center'>
            <div className="w-full  flex flex-col justify-between md:flex-row gap-10">
                <div className="flex items-start justify-start mt-[-10px]  h-full ">
                    <span  className="logo image-control">
                        <Logo></Logo>
                    </span>
                </div>
                <div className=" links-section w-full  max-w-[40%] flex flex-col gap-10 md:gap-0 md:flex-row justify-between items-start md:items-center">
                    <div className="flex flex-col items-left  gap-4 mt-5 md:mt-0">
                        <p className='font-bold'>Products</p>
                        <a href="https://autocomply.norebase.com" target="_blank" >AutoComply</a>
                        <a href="https://autocomply.norebase.com/MoR" target="_blank" >Merchant of Record </a>
                        <a href="https://norebase.gitbook.io/autocomply-api/" target="_blank" >AutoComply API</a>
                    </div>
                    <div className="flex flex-col items-left   gap-4">
                        <p className='font-bold'>Company</p>
                        <a href="https://norebaseloginstorage.blob.core.windows.net/autocomplyassets/Autocomply_terms_of_use.pdf" target="_blank" rel="noopener noreferrer" className="">Terms</a>
                        <a href="https://blog.norebase.com/category/compliance/" target="_blank" >Blog</a>
                        <a href="#faq" >FAQs</a>
                    </div>
                    
                    <div className="flex flex-col justify-start items-start h-full col-span-1  gap-4 ">
                        <div className="flex items-center gap-4">
                            <a href="https://www.instagram.com/autocomply/" target="_blank"><InstagramIcon /></a>
                            <a href="https://www.linkedin.com/pulse/seamless-compliance-autocomply-norebase-mvumf" target="_blank"><LinkedInIcon /></a>
                            <a href="https://x.com/Autocomply" target="_blank"><TwitterIcon /></a>
                        </div>
                        <a href="mailto:sales@norebase.com" >hello@norebase.com</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
     );
}
 
export default FooterComponent;