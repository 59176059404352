import { useState, lazy, Suspense } from 'react';

// Lazy loading SVGs
const AdditionalInfo = lazy(() => import('../../assets/svgs/mor-assets/additional-info-img.svg').then(module => ({ default: module.ReactComponent })));
const RiskImage = lazy(() => import('../../assets/svgs/mor-assets/risk-img.svg').then(module => ({ default: module.ReactComponent })));
const AssessmentImage = lazy(() => import('../../assets/svgs/mor-assets/assessment-img.svg').then(module => ({ default: module.ReactComponent })));
const KycImage = lazy(() => import('../../assets/svgs/mor-assets/run-kyc-image.svg').then(module => ({ default: module.ReactComponent })));

const sectonData = [
    {
        title: 'Run KYC/KYB',
        text: 'Enter user details and verify individual or business identity ensuring compliance and security in just a few clicks. ',
    },
    {
        title: 'Perform PEP/Sanctions Screening',
        text: 'Easily screen for PEPs and sanction lists to protect your company and ensure regulatory compliance.',
    },
    {
        title: 'Conduct Risk Assessment',
        text: 'Assess risks to analyze potential threats and ensure informed decision-making for your company. ',
    },
    {
        title: 'Audit results and receive a report',
        text: 'Conduct a comprehensive audit on all results received and receive a summary report.',
    },
];

const OnboardingCompliantClient = () => {
    const [activeTab, setActiveTab] = useState(0);

    

    return (
        <section className="mt-20 md:px-40 py-[7.5rem] bg-[#104330] w-full">
            <div className="card-width  px-[10px]">
                <div className="flex flex-col items-center justify-center max-w-[90%] md:max-w-[100%]">
                    <p className="md:text-[52px] text-[36px] md:text-center text-start tracking-[-1px] text-white font-[600] ">Onboard Compliant Clients with Ease</p>
                </div>

                <div className="grid grid-cols-1 md:gap-16 md:grid-cols-2">
                    <div className='block md:hidden'>
                        {sectonData.map((item, index) => (
                            <div key={item.title} className="my-20">
                                {
                                    
                                    
                                        <button  onClick={() => setActiveTab(index)} className={`flex flex-col gap-3 items-start`}>
                                                <p className={`text-left text-[18px] font-[600] text-[#fff] `}>{item.title}</p>
                                                <p className={`text-left text-[16px] font-[400] text-[#fff] `}>{item.text}</p>

                                        </button>
                                    
                                }
                            </div>
                        ))} 
                    </div>
                    <div className='hidden md:block'>
                        {sectonData.map((item, index) => (
                            <div key={item.title} className="my-[4rem]">
                                <div>
                                    <button 
                                        onMouseOver={() => setActiveTab(index)}
                                        onFocus={() => setActiveTab(index)}
                                        onClick={() => setActiveTab(index)} 
                                        className={`flex gap-10 items-center`

                                    }>
                                        <div className={`${activeTab === index ? 'bg-white' : 'bg-[#ffffff5e]'} px-16 py-6 rounded-[5px] text-[#228B22] text-[40px]`}>{index + 1}</div>
                                        <div className="">
                                            <p className={`text-left text-[24px] font-[600] ${activeTab === index ? 'text-[#fff]' : 'text-[#ffffff5e]'}`}>{item.title}</p>
                                            <p className={`text-left text-[16px] font-[400] ${activeTab === index ? 'text-[#fff]' : 'text-[#ffffff5e]'}`}>{item.text}</p>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="w-full flex flex-col items-end justify-center">

                        
                        <div className='max-h-[400px] hidden md:block h-[400px]'>
                            <Suspense fallback={<div className='text-white'>Loading image...</div>}>
                                {activeTab === 0 && <KycImage className="w-full h-full" />}
                                {activeTab === 1 && <AdditionalInfo className="w-full h-full" />}
                                {activeTab === 2 && <RiskImage className="w-full h-full" />}
                                {activeTab === 3 && <AssessmentImage className="w-full h-full" />}
                            </Suspense>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OnboardingCompliantClient;
