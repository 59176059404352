import React from 'react'
import { VscClose } from 'react-icons/vsc'

const ModalWrapper = ({ onClose, children, wide, height }: { onClose: () => void, children: JSX.Element | JSX.Element[], wide?: boolean, height?: string }) => {
    return (
        <div className={`transition scale-105 duration-3 fixed z-40 h-screen top-0 left-0 flex items-center justify-center w-full`}>
            <button onClick={onClose} className='h-screen w-full absolute z-20 bg-black opacity-70'></button>
            <div className={`p-8 rounded-lg w-11/12 h-3/4 ${height ?? 'md:h-3/6'} lg:max-h-fit bg-white  md:w-3/5 ${wide ? 'lg:max-w-5xl' : 'lg:max-w-4xl' }  z-40 overflow-y-auto relative`}>
                <button onClick={onClose} className='absolute cursor-pointer z-[99999] flex items-center justify-center px-6 right-0'>
                    <VscClose onClick={onClose} size={25} className='text-grey2 cursor-pointer'/>
                </button>
                { children }
            </div>
        </div>
    )
}

export default ModalWrapper