
import Button from "../../ui/UIButton";
import { useAnimateOnScroll } from '../../../hooks/useAnimateOnScroll';
import { useNavigate } from "react-router-dom";

type PropsType = {
    title: string;
    subtext: string;
    buttonText: string;
    animationUrl: string;
};

const AutoComplyUseCase = ({ title, subtext, buttonText, animationUrl }: PropsType) => {

    const { isVisible, elementRef } = useAnimateOnScroll(0.1);
    const navigate = useNavigate()

    return (
        <div
            ref={elementRef}
            className=" w-full md:px-40 "
        >
            <div 
            className={` card-width flex flex-col min-h-[620px] md:my-40 md:flex-row justify-between items-center p-6 space-y-6 md:space-y-0 md:space-x-6 transition-opacity transform duration-500 ${
                isVisible
                    ? 'animate-slideInFromBottom opacity-100 translate-y-0'
                    : 'opacity-0 translate-y-10'
            }`}
            >

                <div className="section-a flex flex-col gap-8 md:gap-y-12 w-full md:w-1/2 pl-5">
                    <h1 className="md:text-[40px] text-[25px] text-left  md:text-start font-[600] tracking-[-1px] max-w-[75%]">{title}</h1>
                    <p className="md:text-[24px] text-[18px] font-[400] md:pr-48">{subtext}</p>
                    <Button onClick={() => navigate('/login')} className="px-6 py-2 bg-primaryGreen font-[600] text-[14px] md:text-[20px] text-white max-w-[340px] flex items-center justify-center rounded-lg">
                        {buttonText}
                    </Button>
                </div>

                <div className="section-b md:w-1/2 w-full flex justify-center ">
                    <div className="h-[400px] w-full flex items-center justify-center">
                        <video
                            src={animationUrl}
                            className="w-full h-auto rounded-lg"
                            autoPlay
                            muted
                            loop
                            playsInline
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AutoComplyUseCase;
