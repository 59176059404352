import { useState } from "react";
export type PropsType = {
    title: string,
    answer: string,
    type: string,
    list?: string[];
}

const Accordion = ({title, answer, type, list}:PropsType) => {
    const [accordionOpen, setAccordionOpen] = useState(false)
    return ( 
        <div className={` ${type === 'help' ? 'py-2' : 'py-10'} bg-transparent border-b border-[#dedcdc]`}>

            <button onClick={() => setAccordionOpen(!accordionOpen)} className="flex justify-between items-center w-full">

                <span className={`font-bold text-left  ${type === 'help' ? 'text-[16px]' : 'md:text-[20px] text-[16px]'}`}>{title}</span>
                { 
                    accordionOpen ?  
                    <span className={` font-[900] ${type === 'help' ? 'text-[25px]' : 'text-[30px]'}`}>-</span>
                    :  
                    <span className={` font-[900] ${type === 'help' ? 'text-[25px]' : 'text-[30px]'}`}> + </span>
                }

            </button>

            <div className={`grid overflow-hidden transition-all duration-200 ease-in-out text-[#646468]
            ${accordionOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}
            `}>

                {
                    answer &&
                    <div className={`overflow-hidden  ${type === 'help' ? 'text-[16px] w-[98%]' : 'md:text-[18px] text-[16px] md:w-[80%] w-[100%]'}`}>
                        {answer}
                    </div>
                }

                {
                    list && accordionOpen && 
                    <ul className="ml-8">
                        {
                          list.map((item: string) => (
                            <li key={item} className="text-[16px] my-2">{item}</li>
                          ))  
                        }
                    </ul>
                }

            </div>

        </div>
     );
}
 
export default Accordion;