import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Route, } from 'react-router-dom';
import './sass/main.scss'


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { MsalProvider } from '@azure/msal-react';
import { StartAuthentication } from 'norebase-authentication'

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Util Imports
import { msalInstance } from './utils/azureAdB2cService/msalSetup';
import { baseUrl, setupAxiosInterceptors } from './utils/AxiosConfig';

import { store } from './store/ConfigureStore';
import { AppContext } from './utils/AppContext';
import { MemberAccessList, UserProfile } from './utils/typings/settings';

import { SignalRProvider } from './context/signalRContext';
import LandingPageNew from './pages/landingpage/LandingPageNew'

setupAxiosInterceptors();


const PageLoader = React.lazy(() => import('./components/shared/PageLoader'));
const LandingPage = React.lazy(() => import('./pages/landingpage/LandingPage'));
const MorLandingPage = React.lazy(() => import('./pages/landingpage/MoRlandingPage'));
const Overview = React.lazy(() => import('./pages/home/compliance/Overview'));
const Compliance = React.lazy(() => import('./pages/home/compliance/ComplianceDetails'));
const Notifications = React.lazy(() => import('./pages/home/compliance/Notifications'))
const Teams = React.lazy(() => import('./pages/teams/Teams'));
const Filling = React.lazy(() => import('./pages/filing/Filling'));
const CreateCompliance = React.lazy(() => import('./pages/home/compliance/CreateCompliance'));
const Documents = React.lazy(() => import('./pages/documents/Documents'));
const Screening = React.lazy(() => import('./pages/screening/Screening'));
const ToolsLandingPage = React.lazy(() => import('./pages/tools/ToolsLandinPage'));
const SingleFolder = React.lazy(() => import('./components/pages/documents/SingleFolder'));
const SubTasksPage = React.lazy(() => import('./components/pages/compliance/complianceDetails/SubTasks'));
const Pricing = React.lazy(() => import('./components/pages/settings/BillingTab/Pricing'));
const AssignPage = React.lazy(() => import('./components/pages/compliance/task/AssignPage'));
const InternalTaskForm = React.lazy(() => import('./components/pages/compliance/task/InternalTaskForm'));
const ExternalTaskSubmission = React.lazy(() => import('./components/pages/compliance/task/ExternalTaskForm'));
const ObligationOverview = React.lazy(() => import('./pages/home/companyhealth/CompanyHealthOverview'));
const Settings = React.lazy(() => import('./pages/settings/Settings'))
const CalendarHome = React.lazy(() => import('./pages/home/calendar/CalendarHome'));
const ClientOnboarding = React.lazy(() => import('./pages/clientonboarding/ClientOnboarding'));
const AddProspectsPage = React.lazy(() => import('./components/pages/clientonboarding/prospects/addprospects/AddProspectsPage'));
const ScreeningPage = React.lazy(() => import('./components/pages/clientonboarding/screening/ScreeningPage'));
const ScreeningPreview = React.lazy(() => import('./components/pages/clientonboarding/screeningpreview/ScreeningPreview'));
const ClientPreview = React.lazy(() => import('./components/pages/clientonboarding/clients/ClientPreview'));
const Onboarding = React.lazy(() => import('./pages/onboarding/Onboarding'));
const HelpCenter = React.lazy(() => import('./pages/helpcenter/HelpCenter'));


const BackOfficeOverview = React.lazy(() => import('./pages/backoffice/Overview'));
const CompanyObligations = React.lazy(() => import('./pages/backoffice/obligations/CompanyObligations'));
const AddObligation = React.lazy(() => import('./pages/backoffice/obligations/AddObligation'));
const TaskManagement = React.lazy(() => import('./pages/backoffice/TaskManagement'));
const TaskCreationOverview = React.lazy(() => import('./pages/backoffice/TaskCreationOverview'));
const NoTemplateTask = React.lazy(() => import('./components/pages/back-office/NoTemplateTask'));
const NoTemplateManagement = React.lazy(() => import('./pages/backoffice/task-management/NoTemplateManagement'));


const theme = createTheme({
});


function App() {

  const [userEditAccessList, setUserEditAccessList] = useState<MemberAccessList[]>([])
  const [userProfile, setUserProfile] = useState<UserProfile>()

  const contextData = {
    setEditAccessList: (data: MemberAccessList[]) => setUserEditAccessList(data),
    userEditAccessList,
    setUserProfile: (data: UserProfile) => setUserProfile(data),
    userProfile: userProfile!
  }

  const webSocketUrl = new URL(`${baseUrl}`)
  webSocketUrl.pathname = 'websockethub'

  return (
    <SignalRProvider url={webSocketUrl.href}>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ToastContainer
                  position="top-center"
                  hideProgressBar={true}
                  autoClose={5000}
                  newestOnTop={true}
                  closeOnClick
                  pauseOnHover
                  theme="colored"
                  transition={Slide}
                  draggableDirection="y"
                />
                <React.Suspense fallback={<PageLoader />}>
                  <AppContext.Provider value={contextData}>
                    <StartAuthentication
                      tokenName='access_token'
                      routeAfterLogin='/dashboard'
                      routeAfterSignup='/dashboard'
                      platform='vault'
                      landingPageRoute={
                        <Route path='/' element={<LandingPageNew />} />
                      }
                      authenticatedRoutes={
                        <>
                          <Route path='/new' element={<LandingPage />} />
                          <Route path='/dashboard' element={<Overview />} />
                          <Route path='/onboarding' element={<Onboarding />} />
                          <Route path='/dashboard/overview' element={<ObligationOverview />} />
                          <Route path='/dashboard/calendar' element={<CalendarHome />} />
                          <Route path='/dashboard/compliance/:complianceId/setup' element={<Compliance />} />
                          <Route path='/notifications' element={<Notifications />} />
                          <Route path='/compliance/:complianceId/task/:taskId' element={<SubTasksPage />} />
                          <Route path='create-compliance' element={<CreateCompliance />} />
                          <Route path='teams' element={<Teams />} />
                          <Route path='/tools/screening' element={<Screening />} />
                          <Route path='/tools/client-onboarding' element={<ClientOnboarding />} />
                          <Route path='/tools/add-prospect/:clientId' element={<AddProspectsPage />} />
                          <Route path='/tools/client-screening/:clientId' element={<ScreeningPage />} />
                          <Route path='/tools/screening-preview/:clientId' element={<ScreeningPreview />} />
                          <Route path='/tools/client-preview/:clientId' element={<ClientPreview />} />
                          <Route path='/tools' element={<ToolsLandingPage />} />
                          <Route path='/documents' element={<Documents />} />
                          <Route path='/documents/folder/:id' element={<SingleFolder />} />
                          <Route path='/assign' element={<AssignPage />} />
                          <Route path='/assign/assign-task/:companyId/:complianceId/:stepId' element={<InternalTaskForm />} />
                          <Route path='/filing/success' element={<Filling />} />
                          <Route path='/pricing' element={<Pricing />} />
                          <Route path='/settings' element={<Settings />} />
                          <Route path='/help' element={<HelpCenter />} />
                          <Route path='/backoffice' element={<BackOfficeOverview />} />
                          <Route path='/backoffice/compliance' element={<CompanyObligations />} />
                          <Route path='/backoffice/add-compliance' element={<AddObligation />} />
                          <Route path='/backoffice/task-management/template/:complianceId' element={<TaskManagement />} />
                          <Route path='/backoffice/task-management/no-template/:complianceId' element={<NoTemplateTask />} />
                          <Route path='/backoffice/no-template-management/:complianceId' element={<NoTemplateManagement />} />
                          <Route path='/backoffice/task-management/:complianceId' element={<TaskCreationOverview />} />
                        </>
                      }
                      unAuthenticatedRoutes={
                        <>
                          <Route path='/steps-form/:companyId/:complianceId/:stepId' element={<ExternalTaskSubmission />} />
                          <Route path='/MoR' element={<MorLandingPage />} />
                        </>
                      }
                    >
                    </StartAuthentication>
                  </AppContext.Provider>

                </React.Suspense>
              </LocalizationProvider>
            </ThemeProvider>
          </Provider>
        </BrowserRouter>
      </MsalProvider>
    </SignalRProvider>
  )
}

export default App;

